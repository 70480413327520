<template>
  <!--
    The main container for the Students Table Page.
    This template displays a list of students in a group with
    nested tasks, advanced tasks, and the ability to evaluate/grade each item.
  -->
  <div class="col">
    <!--
      Display basic group info (name, teacher, etc.)
      if the group data is not currently loading.
    -->
    <div v-if="!$store.getters['groups/load']">
      <Info
        v-if="groupInfo != {}"
        :items="groupInfo"
        :style="{ 'direction: rtl': getLocale == 'ar' }"
      />
    </div>

    <!--
      The main data table to show students of the group.
      Renders nested tasks in an 'expand' column.
    -->
    <el-table
      :data="this.studentsData.groups"
      stripe
      size="medium"
      style="table-layout: auto"
      v-loading="
        $store.getters['user/usersLoad'] ||
        $store.getters['groups/load'] ||
        $store.getters['courses/load']
      "
    >
      <!-- Expand column to show tasks and advanced tasks nested -->
      <el-table-column type="expand">
        <template #default="{ row }">
          <!-- Embedded table for "tasks" -->
          <el-table :data="row.tasks" stripe size="mini" fit>
            <el-table-column sortable prop="id" label="#" />
            <el-table-column prop="title" :label="$t('activity')">
              <template #default="{ row }">
                {{ getLocalizedText(row.title) }}
              </template>
            </el-table-column>
            <el-table-column sortable prop="created_at" :label="$t('date')">
              <template #default="{ row }">
                {{
                  row.created_at ? toLocalDatetime(row.created_at, "en") : "_"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="badge_id" :label="$t('badge')">
              <template #default="{ row }">
                <i class="fa fa-shield fa-2x text-warning"></i>
                <p>{{ labels[row.badge_id - 1] }}</p>
              </template>
            </el-table-column>
            <el-table-column sortable prop="grade" :label="$t('evaluation')">
              <template #default="{ row }">
                <el-rate v-model="row.grade" disabled text-color="#ff9900" :max="3"/>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              prop="status"
              :label="$t('admin_dashboard_type.status')"
            >
              <template #default="{ row }">
                <el-badge
                  :value="activityStatus(row.status).value"
                  :type="activityStatus(row.status).type"
                />
              </template>
            </el-table-column>
            <el-table-column prop="" label="_">
              <template #default="{ row }">
                <div class="d-flex justify-content-between">
                  <!-- Edit activity -->
                  <i
                    class="fa fa-edit text-info"
                    style="cursor: pointer"
                    @click="openStudentActivityDialog(row)"
                    v-tooltip.top-center="$t('review_active')"
                  ></i>
                  <!-- Delete activity -->
                  <i
                    v-tooltip.top-center="$t('delete_active')"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    @click="deleteActivityEvaluation(row.id)"
                  ></i>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- Embedded table for "AdvancedTasks" -->
          <el-table
            :data="row.AdvancedTasks"
            stripe
            size="mini"
            fit
            style="margin-top: 15px"
          >
            <el-table-column sortable prop="id" label="#" />
            <el-table-column prop="title" :label="$t('activity')">
              <template #default="{ row }">
                {{ getLocalizedText(row.activity_name) }}
              </template>
            </el-table-column>
            <el-table-column sortable prop="title" :label="$t('advanced')">
              <template #default="{ row }">
                {{ row.advanced_id }}
              </template>
            </el-table-column>
            <el-table-column sortable prop="created_at" :label="$t('date')">
              <template #default="{ row }">
                {{ row.date ? toLocalDatetime(row.date, "en") : "_" }}
              </template>
            </el-table-column>
            <el-table-column prop="badge_id" :label="$t('badge')">
              <template #default="{ row }">
                <i class="fa fa-shield fa-2x text-warning"></i>
                <p>{{ labels[row.badge_id - 1] }}</p>
              </template>
            </el-table-column>
            <el-table-column sortable prop="grade" :label="$t('evaluation')">
              <template #default="{ row }">
                <el-rate v-model="row.grade" disabled text-color="#ff9900" :max="3"/>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              prop="status"
              :label="$t('admin_dashboard_type.status')"
            >
              <template #default="{ row }">
                <el-badge
                  :value="activityStatus(row.status).value"
                  :type="activityStatus(row.status).type"
                />
              </template>
            </el-table-column>
            <el-table-column prop="" label="_">
              <template #default="{ row }">
                <div class="d-flex justify-content-between">
                  <i
                    class="fa fa-edit text-info"
                    style="cursor: pointer"
                    @click="openStudentActivityDialog(row)"
                    v-tooltip.top-center="$t('review_active')"
                  ></i>
                  <i
                    v-tooltip.top-center="$t('delete_active')"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    @click="deleteActivityEvaluation(row.id)"
                  ></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>

      <!-- Column: student_name -->
      <el-table-column
        sortable
        prop="student_name"
        :label="$t('admin_dashboard_type.student_name')"
      >
        <template #default="{ row }">
          <router-link
            class="switch_routing"
            :to="`/groups/group/students/profile/${row.id}`"
          >
            {{ row.student_name }}
          </router-link>
        </template>
      </el-table-column>

      <!-- Column: statistics -->
      <el-table-column sortable prop="statistics" :label="$t('statistics')">
        <template #default="{ row }">
          <span v-for="(statistic, key) in row.statistics" :key="key">
            <el-badge
              :value="`${activityStatus(statistic.status).value} : ${
                statistic.count
              }`"
              :type="activityStatus(statistic.status).type"
            />
          </span>
        </template>
      </el-table-column>

      <!-- Column: student status (active, non-active, hidden) -->
      <el-table-column prop="status" :label="$t('admin_dashboard_type.status')">
        <template #default="{ row }">
          <el-badge
            v-if="row.status == 0"
            :value="$t('non-active')"
            type="danger"
          />
          <el-badge
            v-if="row.status == 1"
            :value="$t('active')"
            type="primary"
          />
          <el-badge
            v-if="row.status == 3"
            :value="$t('hidden')"
            type="warning"
          />
        </template>
      </el-table-column>

      <!-- Column: block/unblock student -->
      <el-table-column :label="$t('options')">
        <template #default="{ row }">
          <div>
            <el-button
              type="primary"
              v-if="row.status != 2"
              @click="blockStudentStatus(row.group_id, row.id)"
            >
              {{ $t("Block") }}
            </el-button>
            <el-button
              type="danger"
              v-else
              @click="
                changeStudentStatus({
                  student_id: row.id,
                  group_id: row.group_id,
                  status: 1,
                })
              "
            >
              {{ $t("Unblock") }}
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!--
      Dialog to edit student activity evaluation:
      Allows teachers to grade, give feedback,
      select badges, etc.
    -->
    <el-dialog
      :title="$t('student_activity')"
      width="75%"
      :visible.sync="studentActivityDialog"
      top="4vh"
      :show-close="false"
      :class="dialogClass"
      modal
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="editedStudentActivity"
        v-loading="$store.getters['courses/load']"
        ref="studentActivityDialogRef"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :lg="24">
            <!--
              If advanced_id is not set, show a main image for the activity.
              Otherwise, show toggled views for advanced vertical/horizontal objects.
            -->
            <div class="image-container">
              <img
                v-if="!editedStudentActivity.advanced_id"
                :src="imageSrc"
                :alt="editedStudentActivity.name"
                class="mb-2 unique_img"
                draggable="false"
                id="uniqueImg"
                loading="lazy"
              />
            </div>

            <!-- Button to toggle between horizontal/vertical for advanced tasks -->
            <div
              class="toggle-button-container"
              v-if="editedStudentActivity.advanced_id == 1"
            >
              <el-button @click="toggleObjects">
                {{
                  showHorizontal ? "Show Horizontal Mode" : "Show Vertical Mode"
                }}
              </el-button>
            </div>

            <!--
              For advanced tasks: Show images in chunks
              (vertical_objects or horizontal_objects),
              toggled with showHorizontal
            -->
            <div class="wrapper_of_images">
              <div
                v-for="(imageGroup, groupIndex) in splitImages(
                  showHorizontal
                    ? editedStudentActivity.vertical_objects
                    : editedStudentActivity.horizontal_objects,
                  25
                )"
                :key="groupIndex"
                class="main_main"
              >
                <div
                  :class="getWrapperClass(editedStudentActivity.advanced_id)"
                >
                  <img
                    v-for="(img, i) in imageGroup"
                    :key="i"
                    :style="{ transform: `rotate(${-img.rotation}deg)` }"
                    class="item"
                    :src="img.img_path"
                    alt="draw image"
                    :class="`custom_img_en_class_${i + 1}`"
                  />
                </div>
                <div class="text_images"></div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!--
            Left column:
            Show teacher feedback or message (if any).
            Also includes link to 3D printing (if advanced_id or standard),...
          -->
          <el-col :lg="12" :md="12">
            <div v-if="editedStudentActivity.message">
              <p class="h5">{{ $t("message") }}</p>
              <p class="p-2 bg-light">
                {{ getLocalizedText(editedStudentActivity.message) }}
              </p>
            </div>

            <div
              v-if="
                editedStudentActivity.feedback ||
                editedStudentActivity.advanced_id
              "
            >
              <p class="h5">{{ $t("feedback") }}</p>
              <p class="p-2 bg-light">
                {{ getFeedback(editedStudentActivity.feedback) }}
              </p>
            </div>

            <!--
              Display the status badge (Accepted, Resubmit, etc.)
              plus a radio group to change it if teacher has permission.
            -->
            <p class="h5">
              {{ $t("status") }}:
              <el-badge
                :value="activityStatus(radio_status).value"
                :type="activityStatus(radio_status).type"
              />
            </p>
            <el-radio-group v-model="editedStudentActivity.status">
              <el-radio :label="1">Accepted</el-radio>
              <el-radio :label="2">Accepted With Modification</el-radio>
              <el-radio :label="3">Resubmit</el-radio>
            </el-radio-group>

            <div class="text-center">
              <i class="fa fa-shield fa-3x text-warning"></i>
              <p class="h5">
                {{
                  labels[editedStudentActivity.badge_id - 1]
                    ? labels[editedStudentActivity.badge_id - 1]
                    : $t("no_eval")
                }}
              </p>
            </div>

            <div class="three_d_renders d-flex">
              <!--
                Link to 3D printing route. Different routes for advanced or normal
              -->
              <router-link
                v-if="editedStudentActivity.advanced_id"
                class="links shadow-sm"
                target="_blank"
                :to="`/img/${editedStudentActivity.type_id}/${
                  editedStudentActivity.user_id
                }/${editedStudentActivity.advanced_id}/${
                  editedStudentActivity.lesson_id
                }/${showHorizontal ? 'v' : 'h'}`"
              >
                3D Printing
              </router-link>

              <router-link
                v-else
                class="links shadow-sm"
                target="_blank"
                :to="`/img/3d/${editedStudentActivity.id}`"
              >
                3D Printing
              </router-link>
            </div>
          </el-col>

          <!-- Right column: show course, student, activity name, etc. -->
          <el-col :lg="12" :md="12">
            <p class="h5">
              {{ $t("the_course_name") }}:
              <router-link :to="`/course/${editedStudentActivity.parent_id}`">
                {{ getLocalizedText(editedStudentActivity.course) }}
              </router-link>
            </p>
            <p class="h5">
              {{ $t("admin_dashboard_type.student_name") }} :
              {{ editedStudentActivity.name }}
            </p>
            <p class="h5">
              {{ $t("activity") }} :
              {{ getLocalizedText(editedStudentActivity.title) }}
            </p>

            <!-- Example: Show the student's age from the group data if available -->
            <div v-for="(students, index) in studentsData.groups" :key="index">
              <h5 v-if="students.id == editedStudentActivity.user_id">
                {{ $t("admin_dashboard_type.age") }}: {{ students.age }}
              </h5>
            </div>

            <!--
              Select feedback from a list of preset feedback messages (parsedFeedbacks).
            -->
            <el-form-item :label="$t('feedback')" prop="feedback_id">
              <el-select
               v-model="editedStudentActivity.selected_feedback"
                :placeholder="$t('feedback')"
                style="width: 100%"
                :key="parsedFeedbacks.length"
                @change="updateFeedbackExplanation"
              >
                <el-option
                  v-for="(item, index) in parsedFeedbacks"
                  :key="index"
                  :label="item.grade"
                  :value="item.grade"
                />
              </el-select>
            </el-form-item>

            <!--
              Additional feedback text (teacher can type freely)
            -->
            <el-form-item
              v-if="hasRole('teacher')"
              :label="$t('feedback')"
              prop="feedback"
            >
              <el-input
                type="textarea"
                v-model="editedStudentActivity.feedback"
              />
            </el-form-item>

            <!--
              Rate the activity (stars)
            -->
            <p class="h5">
              {{ $t("grade") }}:
              <el-rate v-model="editedStudentActivity.grade" :max="3"/>
            </p>

            <!--
              Badge rating (shield icons)
              used for awarding a badge level
            -->
            <p class="h5">
              {{ $t("badge") }}:
              <el-rate
                v-model="editedStudentActivity.badge_id"
                :icon-classes="[
                  'fa fa-shield ',
                  'fa fa-shield ',
                  'fa fa-shield ',
                  'fa fa-shield ',
                  'fa fa-shield ',
                ]"
                void-icon-class="fa fa-shield"
                :colors="[
                  '#00bcd4',
                  '#00bcd4',
                  '#00bcd4',
                  '#00bcd4',
                  '#00bcd4',
                ]"
                :texts="labels"
                :show-text="true"
              />
            </p>

            <el-checkbox
              v-model="gallery_checked"
              :label="$t('Add to gallery')"
              size="large"
            />
          </el-col>
        </el-row>
      </el-form>

      <!-- Dialog Footer: Cancel & Save buttons -->
      <div class="creative_footer_dialog">
        <!--
              delete icon for removing the activity
            -->
        <div class="d-flex justify-content-between activity-rate">
          <i
            class="fa fa-trash fa-3x text-danger mr-3"
            @click="deleteActivityEvaluation(editedStudentActivity.id)"
          ></i>
        </div>
        <el-button class="text-capitalize cancel_button" @click="resetDialog">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-capitalize save_button"
          @click="saveActivityEvaluation"
        >
          {{ $t("save_eval") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- Pagination -->
    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="this.studentsData.count"
      style="margin-top: 20px"
    />
  </div>
</template>

<script>
/**
 * Imports
 */
import Info from "../../components/Cards/Info.vue";
import Swal from "sweetalert2";

export default {
  name: "groupStudent",
  components: { Info },
  data() {
    return {
      imageSrc: require("@/assets/images/Loading_icon.gif"),

      // For pagination client-side
      currentPage: 1,
      pageSize: 10,

      // For toggling "Add to Gallery"
      gallery_checked: 0,

      // Basic info about the group
      groupInfo: {},

      // Full data from server
      studentsData: null,

      // Label array for badges (indexed from 0..4)
      labels: [
        `${this.$t("no_eval")}`,
        `${this.$t("speed")}`,
        `${this.$t("perfection")}`,
        `${this.$t("quality")}`,
        `${this.$t("excellence")}`,
      ],

      // Dialog to edit student activity
      studentActivityDialog: false,
      editedStudentActivity: {},
      radio_status: "",
      // Status values for tasks
      activityStatusValues: {
        1: "Accepted",
        2: "Accepted With Modification",
        3: "Resubmit",
      },

      // radioError: false,
      // radio_status:0,
      // Toggle advanced tasks between vertical/horizontal
      showHorizontal: false,
    };
  },
  computed: {
    /**
     * Current locale (e.g., 'ar' or 'en').
     */
    getLocale() {
      return this.$i18n.locale;
    },

    /**
     * Returns a list of feedback objects in the current locale
     * that match the student's course or are universal (course_id === null).
     */
    parsedFeedbacks() {
      const feedbacks = this.$store.getters["groups/studentFeedback"]
        // .filter(
        //   (el) =>
        //     el.course_id == this.editedStudentActivity.course_id ||
        //     el.course_id === null
        // )
        .map((feedback) => {
          try {
            // Attempt to parse feedback.body as JSON
            const parsedBody = JSON.parse(feedback.body);
            // Return the locale-specific object or fallback to English
            return parsedBody[this.$i18n.locale] || parsedBody["en"];
          } catch (e) {
            // If parsing fails, treat it as a plain string
            console.warn(`Failed to parse feedback body:`, feedback.body);
            return {
              grade: feedback.body,
              explanation: "No explanation provided",
            };
          }
        })
        .flat();

      return feedbacks.map((feedback) => {
        return {
          grade: feedback.grade,
          explanation: feedback.explanation,
        };
      });
    },
  },
  watch: {
    // Whenever the parent component re-renders or `studentActivityDialog` changes
    // TODO Trying to add loading to the image
    studentActivityDialog: {
      immediate: true, // Run on component mount
      handler(newVal) {
        if (newVal) {
          this.imageSrc = require("@/assets/images/Loading_icon.gif"); // Clear previous image first
          this.$nextTick(() => {
            this.imageSrc = this.handleImgSrc(this.editedStudentActivity.id); // Load new image
          });
        }
      },
    },

    /**
     * Watch when 'editedStudentActivity.feedback_id' changes,
     * then look up the corresponding feedback message from the store.
     */
    "editedStudentActivity.feedback_id": function (val) {
      let msg = this.$store.getters["groups/studentFeedback"].filter(
        (el) => el.id == val
      )[0];
      this.editedStudentActivity.feedback = msg ? msg.message : " ";
    },
  },
  mounted() {
    // On mount, fetch group info from server
    this.$store
      .dispatch("groups/getGroupInfo", {
        id: this.$route.params.id,
      })
      .then(() => {
        this.groupInfo = Object.entries(this.$store.getters["groups/groupInfo"])
          .map(([key, value]) => ({ key, value }))
          .filter(
            (item) =>
              item.key !== "id" &&
              item.key !== "course_id" &&
              item.key !== "created_at" &&
              item.key !== "updated_at" &&
              item.key !== "search_text" &&
              item.key !== "teacher_id"
          );
      });

    // Fetch or refresh the list of group students
    this.Refresh();
  },
  methods: {
    getWrapperClass(length) {
      return length === 4 ? "wrapper_eight" : "wrapper_two";
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.Refresh();
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1; // Reset to first page
      this.Refresh();
    },

    /**
     * Toggle between showing advanced vertical vs horizontal objects.
     */
    toggleObjects() {
      this.showHorizontal = !this.showHorizontal;
    },

    /**
     * Splits an array of images into chunks of given chunkSize.
     * Used to display images in smaller grouped grids.
     */
    splitImages(images, chunkSize) {
      if (!images || !Array.isArray(images)) {
        return [];
      }
      let result = [];
      for (let i = 0; i < images.length; i += chunkSize) {
        result.push(images.slice(i, i + chunkSize));
      }
      return result;
    },

    /**
     * Update the feedback explanation when the teacher
     * selects a new feedback grade from the dropdown.
     */
    updateFeedbackExplanation(selectedGrade) {
      const feedback = this.parsedFeedbacks.find(
        (item) => item.grade === selectedGrade
      );
      if (feedback) {
        this.editedStudentActivity.feedback = `${feedback.explanation}`;
      }
    },

    /**
     * Builds the image URL for a student's activity content.
     */
    handleImgSrc(id) {
      return `${process.env.VUE_APP_BASE_URL}evaluation/content/${id}`;
    },

    /**
     * Refresh or fetch student data from the store, typically for pagination.
     */
    Refresh() {
      let query = { offset: (this.currentPage - 1) * this.pageSize, limit: this.pageSize-1, order_by: "id" }
      this.$store
        .dispatch("groups/getGroupStudents", { id: this.$route.params.id, query })
        .then((res) => {
          this.studentsData = res;
        });
    },

    /**
     * Reset the dialog state without saving changes.
     * Reverts radio status, closes the dialog, etc.
     */
    resetDialog() {
      this.studentActivityDialog = false;
      this.radioError = false;
      this.showHorizontal = false;
      this.radio_status = this.editedStudentActivity.status;
    },

    /**
     * Change student group status (e.g., block/unblock).
     */
    changeStudentStatus(query) {
      Swal.fire({
        text: query.status
          ? "هل تريد حظر هذا المستخدم ؟ "
          : "هل تريد الغاء حظر هذا المستخدم ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "تأكيد",
        cancelButtonText: "الغاء",
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/changeStudentGroupStatus", query)
            .then(() => {
              this.Refresh();
              Swal.fire({
                text: query.status
                  ? "تم حظر هذا المستخدم"
                  : "تم الغاء حظر هذا المستخدم",
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },

    /**
     * Block a specific student's status in the group.
     */
    blockStudentStatus(id, student_id) {
      Swal.fire({
        text: "هل تريد حظر هذا المستخدم ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "تأكيد",
        cancelButtonText: "الغاء",
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/blockStudentInGroupStatus", {
              id,
              student_id,
            })
            .then(() => {
              this.Refresh();
              Swal.fire({
                text: "تم حظر هذا المستخدم",
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },

    /**
     * Delete the evaluation/activity for a given task ID.
     */
    deleteActivityEvaluation(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("courses/evaluationDelete", id).then(() => {
            this.Refresh();
            Swal.fire({
              text: `${this.$t("success_deleted")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    /**
     * Open the dialog to edit a student activity:
     * copies the 'item' into editedStudentActivity for two-way binding.
     */
    openStudentActivityDialog(item) {
      this.studentActivityDialog = true;
      // Spread 'item' to avoid mutating original data directly.
      this.editedStudentActivity = { ...item };
      // If there's no selected feedback, set it as empty string to avoid errors.
      if (!this.editedStudentActivity.selected_feedback) {
        this.editedStudentActivity.selected_feedback = item.selected_feedback || ""
      }
      this.radio_status = item.status;
    },
    /**
     * Save the current evaluation changes (feedback, rating, etc.)
     * Sends the data to either 'courses/evaluation' or 'courses/advanced'
     * depending on whether advanced_id is set.
     */
    saveActivityEvaluation() {


      let check_Gallery = this.gallery_checked ? 1 : 0;


      if (!this.editedStudentActivity.selected_feedback) {
        this.editedStudentActivity.selected_feedback =
          this.editedStudentActivity.feedback || "No Feedback";
      }

    const query = {
      user_id: this.editedStudentActivity.user_id,
      feedback: this.editedStudentActivity.feedback,
      // status: 1,
      status: this.editedStudentActivity.status || 1,
      grade: this.editedStudentActivity.grade,
      badge_id: this.editedStudentActivity.badge_id,
      selected_feedback: this.editedStudentActivity.selected_feedback,
      selected: check_Gallery,
      lesson_id: this.editedStudentActivity.course_id,
      id:this.editedStudentActivity.id,
      gallery:1
    };
    console.log('query',query);
    if (this.editedStudentActivity.advanced_id) {
      query.advanced_id = this.editedStudentActivity.advanced_id;
    }

    this.$store
      .dispatch("groups/saveActivityEvaluation", query)
      .then(() => {
        this.Refresh();
        this.gallery_checked = false;
        this.studentActivityDialog = false;
      })
      .catch((err) => console.error("Error saving evaluation:", err));
  },


    /**
     * Parse the feedback body from JSON and return current locale text.
     */
    getFeedback(body) {
      if (!body) {
        return this.$t("No_Title");
      }
      const locale = this.$i18n.locale;
      try {
        const parsedData = JSON.parse(body);
        if (parsedData[locale] && parsedData[locale].length > 0) {
          const feedback = parsedData[locale][0];
          return `${feedback.grade}: ${feedback.explanation}`;
        } else if (parsedData["en"] && parsedData["en"].length > 0) {
          const feedback = parsedData["en"][0];
          return `${feedback.grade}: ${feedback.explanation}`;
        } else {
          return this.$t("No_Title");
        }
      } catch (error) {
        return this.$t("No_Title");
      }
    },

    /**
     * Utility function to parse JSON strings with an i18n locale fallback.
     */
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          return parsedData[locale] || parsedData["en"];
        } catch (error) {
          return jsonString; // Not valid JSON, return raw string
        }
      } else {
        return "";
      }
    },

    /**
     * Returns an object with 'value' and 'type'
     * to be used by <el-badge> for activity status.
     */
    activityStatus(status) {
      switch (status) {
        case 1:
          return {
            value: "Accepted",
            type: "success",
          };
        case 2:
          return {
            value: "Accepted With Modification",
            type: "warning",
          };
        case 3:
          return {
            value: "Resubmit",
            type: "danger",
          };
        default:
          return {
            value: "Pending",
            type: "warning",
          };
      }
    },

    /**
     * Optional role-check method.
     * Adjust logic based on your auth system (e.g., Vuex store or direct import).
     */
    hasRole(role) {
      // Example stub: return true or false based on your store logic
      // e.g., return this.$store.getters['auth/hasRole'](role);
      return this.$store.getters["auth/roles"]?.includes(role);
    },
  },
};
</script>

<style scoped>
/*
  General styling for elements in this component.
  Some ::v-deep overrides Element UI (EL) internal classes.
*/

::v-deep .cell {
  text-align: center;
}

::v-deep sup.el-badge__content {
  height: fit-content;
}

/* This applies to ALL el-table components */
.el-table {
  font-size: 16px; /* Adjust to your preference */
}

::v-deep .el-rate__icon.fa-shield {
  font-size: 25px !important;
}

::v-deep .el-rate__icon {
  font-size: 25px !important;
}
</style>

<style lang="scss" scoped>
/* 3D Printing link styling */
.three_d_renders {
  margin: 50px 0 10px;
  .links {
    border: 1px solid #eee;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 17px;
    &:hover {
      background: #f5f5f5;
    }
  }
}

/* Dialog footer for the Save/Cancel buttons */
.creative_footer_dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  .save_button {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.1s;
    font-weight: 500;
    outline: 0;
    min-width: 140px;
    margin-left: 10px;
  }
  .cancel_button {
    color: #fff;
    background-color: #606266;
    border-color: #606266;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.1s;
    font-weight: 500;
    outline: 0;
    min-width: 140px;
  }
}

.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the container has height */
  width: 100%; /* Ensure full width */
  text-align: center; /* Fallback for inline elements */
  overflow: hidden; /* Prevent image overflow */
}

.unique_img {
  max-width: 100%; /* Image cannot exceed parent */
  max-height: 100%; /* Prevents vertical overflow */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
}


/* Container for advanced objects or images in chunked display */
.wrapper_of_images {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */

  .main_main {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content inside */
    justify-content: center;
    .wrapper_two {
      display: flex;
      justify-content: center; /* Center items inside */
      align-items: center;
      margin: auto; /* Center the box */
      flex-wrap: wrap;

      width: 200px;
      height: 200px;
      background: #fff;

      .item {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
    .wrapper_eight {
      display: flex;
      justify-content: center; /* Center items inside */
      align-items: center;
      margin: auto; /* Center the box */
      flex-wrap: wrap;

      width: 100px;
      height: 100px;
      background: #fff;

      .item {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
    .text_images {
      display: flex;
      justify-content: center;
      gap: 6px;
    }
  }
}

/* Toggle button container for advanced tasks, center the button nicely */
.toggle-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>
